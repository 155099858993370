import React, { FC } from 'react';
import { OverlappingCard } from 'wix-ui-tpa/cssVars';
import { ServiceLayoutProps } from '../ServiceCard';
import { classes, st } from './Overlapping.st.css';
import { DataHooks } from '../consts';
import { useInvertInfoPosition } from '../../../hooks/useInvertInfoPosition';
import { useExperiments } from '@wix/yoshi-flow-editor';

export const Overlapping: FC<ServiceLayoutProps> = (props) => {
  const { image, info } = props;
  const invertInfoPosition = useInvertInfoPosition();
  const { experiments } = useExperiments();

  return (
    <OverlappingCard
      data-hook={DataHooks.OVERLAPPING}
      media={image}
      info={info}
      className={st(classes.root, {
        hasImage: Boolean(image),
        invert: invertInfoPosition,
        useCornerRadius: experiments.enabled(
          'specs.bookings.AddCardCornerRadius',
        ),
      })}
      invertInfoPosition={invertInfoPosition}
    />
  );
};
